import { Fragment, useMemo, useState } from 'react';

import { getIllustration, mapImage, useContent, wrap } from '@sitecore/common';
import { ContactMethodsRendering } from '@sitecore/types/ContactMethods';
import { Illustrations } from '@sitecore/types/manual/Illustrations';
import { Box, Card, Divider, Grid, Heading, Image, RadioGroup, RadioTile, Stack, Text } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';

import { ContactExpandable } from './components';

const ContactMethods = () => {
  const { fields } = useContent<ContactMethodsRendering>();
  const { options, content } = fields ?? {};

  const isDesktop = useMediaQuery('lg');

  const [contactReason, setContactReason] = useState<string | undefined>(undefined);

  const items = useMemo(() => {
    if (!options || !options?.topicsList) {
      return [];
    }

    return options?.topicsList?.find(option => option?.displayName === contactReason)?.fields?.options?.typesList;
  }, [contactReason, options]);

  const imageProps = mapImage(content?.image);
  const showImage = !!imageProps?.src && isDesktop;

  const gridItemColumn = { initial: '1/-1' };

  return (
    <Grid gridTemplateColumns={{ initial: '1/-1', md: showImage ? '8fr 4fr' : '1/-1' }} columnGap="6">
      <Grid.Item>
        <Stack gap="10">
          <Stack.Item>
            <Heading as="h2" size={{ initial: 'XS', md: 'M' }} color="inherit">
              {wrap(content?.title)}
            </Heading>
          </Stack.Item>
          <Grid gap="6" columns={2} rowGap="12">
            <Grid.Item gridColumn={gridItemColumn}>
              <Stack gap="6">
                <Text size="BodyL">{content?.text.value}</Text>
                <RadioGroup
                  direction={{ initial: 'column', md: 'row' }}
                  name="contactReason"
                  onValueChange={setContactReason}
                  wrap={false}
                  value={contactReason}
                  label="">
                  {options.topicsList.map(option => {
                    const Illustration = option?.fields?.options?.illustration?.value
                      ? getIllustration(option.fields.options.illustration.value as Illustrations)
                      : undefined;
                    return (
                      <RadioTile
                        value={option.name}
                        subtitle={option?.fields?.content?.text?.value}
                        key={option?.name}
                        icon={Illustration && <Illustration size="small" color="currentColor" />}>
                        {option?.fields?.content?.title?.value ?? ''}
                      </RadioTile>
                    );
                  })}
                </RadioGroup>
              </Stack>
            </Grid.Item>
            <Grid.Item gridColumn={gridItemColumn}>
              <Card>
                {items?.map((item, index) => {
                  return (
                    <Fragment key={item.id}>
                      <Box paddingX="8" paddingY="8">
                        <ContactExpandable item={item} />
                      </Box>
                      {items.length - 1 !== index ? <Divider /> : null}
                    </Fragment>
                  );
                })}
              </Card>
            </Grid.Item>
          </Grid>
        </Stack>
      </Grid.Item>
      {showImage && (
        <Grid.Item justifySelf="center">
          <Image
            maxWidth="100%"
            height="auto"
            sources={imageProps.sources}
            src={imageProps?.src ?? ''}
            alt={imageProps?.alt}
          />
        </Grid.Item>
      )}
    </Grid>
  );
};

export default ContactMethods;
