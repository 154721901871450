import { FC } from 'react';

import logger from '@common/log';
import { useChat } from '@components/chat/useChat';
import { ContactMethod } from '@sitecore/types/ContactMethods';
import { Button } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';

export const ChatButton: FC<{
  contactMethod: ContactMethod | undefined;
  displayName: string;
}> = ({ contactMethod, displayName }) => {
  const isMediumBreakpoint = useMediaQuery('md');

  try {
    const [mount] = useChat();

    if (!contactMethod) {
      logger.error('NtdDKV', 'ChatButton could not render as contactMethod is undefined');
      return null;
    }

    return (
      <Button
        action="primaryRebrand"
        size={isMediumBreakpoint ? 'regular' : 'compact'}
        tone="onLight"
        type="button"
        onClick={() =>
          mount({ visibility: 'open', topic: contactMethod?.chatLink?.fields?.chatBotTopic?.chatTopic?.value })
        }>
        {contactMethod?.buttonText?.value}
      </Button>
    );
    //Saveguard against accidental usage outside ChatProvider
  } catch (e) {
    logger.error('NtdDKV', e instanceof Error ? e.message : 'ChatButton could not render');
    return null;
  }
};
