import { FC } from 'react';

import { wrap } from '@sitecore/common';
import { ContactMethodsTopicOptions } from '@sitecore/types/ContactMethods';
import { Badge, Box, Expandable, Heading, Stack, Text } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { BadgeProps, HeadingProps } from '@sparky/types';

import { ChatButton, RevealPhoneNumber } from '.';

interface ContactExpandableContentProps {
  item: ContactMethodsTopicOptions[0];
}

export interface ContactExpandableTriggerProps {
  title: string;
  badge: {
    color: BadgeProps['color'];
    text: string;
  };
  subtitle?: string;
}

const ContactExpandableContent: FC<ContactExpandableContentProps> = ({ item }) => {
  const { openingHours, contactMethod } = item?.fields ?? {};

  const phoneNumber = contactMethod?.phoneNumberText;

  const hasChatLink = !phoneNumber?.value;

  return (
    <Expandable.Content>
      <Box paddingY="8" paddingLeft={{ initial: '2', md: '6' }}>
        <Stack gap="6">
          {openingHours?.title?.value && openingHours?.content?.value && (
            <Stack gap="6">
              <Stack gap="2">
                <Text size="BodyM" weight="bold">
                  {wrap(openingHours?.title)}
                </Text>
                <Text as="p">{wrap(openingHours?.content)}</Text>
              </Stack>
              {hasChatLink && (
                <Box>
                  <ChatButton contactMethod={contactMethod} displayName={item.displayName} />
                </Box>
              )}
              <RevealPhoneNumber label={contactMethod?.buttonText?.value} phoneNumber={phoneNumber?.value} />
            </Stack>
          )}
        </Stack>
      </Box>
    </Expandable.Content>
  );
};

type Triggerprops = ContactExpandableTriggerProps & {
  headingLevel: HeadingProps['as'];
};

const ContactExpandableTrigger: FC<Triggerprops> = ({ title, headingLevel, badge, subtitle }) => {
  const isMediumBreakpoint = useMediaQuery('md');
  return (
    <Expandable.Trigger label={typeof title === 'string' ? title : ''}>
      <Stack gap="2">
        <Stack direction="row" gap={{ initial: '2', md: '4' }} alignY="center">
          <Heading as={headingLevel} size={{ initial: '3XS', md: 'XS' }}>
            {title}
          </Heading>
          <Stack.Item>
            <Badge size={isMediumBreakpoint ? 'M' : 'S'} color={badge.color}>
              {badge.text}
            </Badge>
          </Stack.Item>
        </Stack>
        {subtitle && <Text as="p">{subtitle}</Text>}
      </Stack>
    </Expandable.Trigger>
  );
};

export const ContactExpandable: FC<{
  item: ContactMethodsTopicOptions[0];
}> = ({ item }) => {
  return (
    <Expandable>
      <Stack>
        <ContactExpandableTrigger
          title={wrap(item?.fields?.content?.title)}
          badge={{
            color: item.fields?.badge?.color.value as BadgeProps['color'],
            text: wrap(item.fields.badge?.text),
          }}
          subtitle={wrap(item.fields.content?.text)}
          headingLevel="h3"
        />
        <ContactExpandableContent item={item} />
      </Stack>
    </Expandable>
  );
};
