import { FC, useState } from 'react';

import { Box, Button, Stack, Text, TextLink } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { MobileIcon } from '@sparky/icons';

const REVEAL_PHONE_NUMBER_DELAY = 3500;

export const RevealPhoneNumber: FC<{
  phoneNumber: string | undefined;
  label: string | undefined;
}> = ({ phoneNumber, label }) => {
  const isMediumBreakpoint = useMediaQuery('md');

  const [isRevealed, setIsRevealed] = useState(false);
  const [isRevealing, setIsRevealing] = useState(false);

  const revealPhoneNumber = () => {
    setIsRevealing(true);
    setTimeout(() => {
      setIsRevealed(true);
      setIsRevealing(false);
    }, REVEAL_PHONE_NUMBER_DELAY);
  };

  if (!phoneNumber) {
    return null;
  }

  if (!isRevealed) {
    return (
      <Box>
        <Button
          isDisabled={true}
          isLoading={isRevealing}
          action="primaryRebrand"
          size={isMediumBreakpoint ? 'regular' : 'compact'}
          tone="onLight"
          type="button"
          onClick={revealPhoneNumber}>
          {label}
        </Button>
      </Box>
    );
  }

  return (
    <Stack direction="row" gap="1">
      <MobileIcon />
      <Text size={{ initial: 'BodyM', md: 'BodyL' }} weight="bold">
        <TextLink href={`tel:${phoneNumber}`} emphasis="medium">
          {phoneNumber}
        </TextLink>
      </Text>
    </Stack>
  );
};
